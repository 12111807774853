import { services } from "redux/services";

const check = <T extends string>(
  collection: Record<T, keyof typeof services>
) => collection;

export const masterPaginationServices = check({
  account: "getAllAccount",
  role: "getAllRole",
  medication: "getAllMedication",
  medicationType: "getAllMedicationType",
  permission: "getAllPermission",
  subscriptionFeature: "getAllSubscriptionFeature",
  user: "getAllUser",
  survey: "getAllSurvey",
  paymentMethod: "getAllPaymentMethod",
  subscription: "getAllSubscription",
  subscriptionPlan: "getAllSubscriptionPlan",
  faq: "getAllFaq",
  inquiry: "getAllInquiry",
  banner: "getAllBanner",
  testimonial: "getAllTestimonial",
  newsletter: "getAllNewsLetter"
});
