import axios, { AxiosPromise } from "axios";
import { API_ENDPOINTS } from "redux/apiEndPoints";
import { AxiosService } from "utils/axios";

interface ParamsState {
  [key: string]: any
}

export const appServices = {
  logoutUser: (params: ParamsState): AxiosPromise => axios.get(API_ENDPOINTS.LOGOUT, { params }),
  changePassword: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.CHANGE_PASSWORD, body),
  dashboard: () => new AxiosService().get(API_ENDPOINTS.DASHBOARD),

  createAccount: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_ACCOUNT, body),
  updateAccount: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_ACCOUNT, body),
  getAccountByUuid: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.GET_ACCOUNT_BY_ID, body),
  getAllAccount: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_ACCOUNT, body),
  deleteAccount: (body: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_ACCOUNT, body),
  updateAccountByUuid: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_ACCOUNT + '/' + body.uuid, body),
  verifyAccount: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.VERIFY_ACCOUNT, body),
  updateAccountStatus: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.UPDATE_ACCOUNT_STATUS, body),
  saveAccountFile: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.SAVE_ACCOUNT_FILE, body),
  deleteAccountFile: (params: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_ACCOUNT_FILE, params),

  createUser: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_USER, body),
  updateUser: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_USER, body),
  getAllUser: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_USER, body),
  getUserByUuid: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.GET_USER_BY_UUID, body),
  deleteUser: (params: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_USER, params),
  getUserLookup: () => new AxiosService().get(API_ENDPOINTS.USER_LOOKUP),
  updateUserFlag: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_USER_FLAG, body),

  generateReport: (params: ParamsState) => new AxiosService().get(API_ENDPOINTS.GENERATE_REPORT, params),

  createSubscription: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.CREATE_SUBSCRIPTION, body),
  getAllSubscription: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_SUBSCRIPTION, body),
  cancelSubscription: (params: ParamsState) => new AxiosService().post(API_ENDPOINTS.CANCEL_SUBSCRIPTION, params),
  getSubscription: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.GET_SUBSCRIPTION, body),
  getSubscriptionPaymentMethod: () => new AxiosService().get(API_ENDPOINTS.GET_SUBSCRIPTION_PAYMENT_METHOD),
  
  getBilling: () => new AxiosService().get(API_ENDPOINTS.GET_BILLING),
  saveBilling: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.SAVE_BILLING, body),

  createFaq: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.CREATE_FAQ, body),
  updateFaq: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_FAQ, body),
  getAllFaq: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_FAQ, body),
  deleteFaq: (body: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_FAQ, body),

  createTestimonial: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_TESTIMONIAL, body),
  updateTestimonial: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_TESTIMONIAL, body),
  getAllTestimonial: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_TESTIMONIAL, body),
  deleteTestimonial: (body: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_TESTIMONIAL, body),

  createBanner: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_BANNER, body),
  updateBanner: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_BANNER, body),
  getAllBanner: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_BANNER, body),
  deleteBanner: (body: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_BANNER, body),

  getAllNewsLetter: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_NEWSLETTER, body),
  getAllInquiry: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_INQUIRY, body),
  
  logout: (): AxiosPromise => axios.post(API_ENDPOINTS.LOGOUT),

}